import { ApolloProvider } from '@apollo/client';
import React, { ReactNode } from 'react';
import { SofClientContext } from '../apolloClient/sofApolloClient/SofClientContext';
import { IntlContextProvider } from './IntlContext/IntlContext';
import { ToastContextProvider } from './ToastContext/ToastContext';
import { ThemeProvider } from '@ccsdk/kingbolt';
import { volvoconnectTheme } from '@ccsdk/vgcs-themes';
import sofApolloClient from '../apolloClient/sofApolloClient/sofApolloClient';
import { TransportOrderReportsContextProvider } from './TransportOrderReportsContext/TransportOrderReportsContext';
import { DashboardFilterContextProvider } from './DashboardFilterContext/DashboardFilterContext';

interface Props {
  children?: ReactNode;
}

const sofClient = sofApolloClient;
const theme = volvoconnectTheme;
theme.palette.text.primary = theme.palette.common.black;

const AppProviders = ({ children }: Props) => (
  <ThemeProvider theme={theme}>
    <ApolloProvider client={sofClient}>
      <SofClientContext.Provider value={{ client: sofClient }}>
        <IntlContextProvider>
          <DashboardFilterContextProvider>
            <TransportOrderReportsContextProvider>
              <ToastContextProvider>{children}</ToastContextProvider>
            </TransportOrderReportsContextProvider>
          </DashboardFilterContextProvider>
        </IntlContextProvider>
      </SofClientContext.Provider>
    </ApolloProvider>
  </ThemeProvider>
);

export default AppProviders;
