import { useContext } from 'react';
import { Auth, Cache } from 'aws-amplify';
import { AuthContext, AuthContextValue } from '../../context/AuthContext';
import { SofApplicationContext } from '../../context/SofApplicationContext/SofApplicationContext';

export interface CognitoUser {
  username: string;
  Session: string;
  authenticationFlowType: string;
  challengeName?: string;
  challengeParam?: any;
  client: any;
  keyPrefix: string;
  pool: {
    userPoolId: string;
    clientId: string;
  };
  storage: any;
  userDataKey: string;
  attributes?: {
    sub: string;
    email_verified: boolean;
    email: string;
    given_name: string;
    family_name: string;
  };
}

export const useAuth = () => {
  const { setUser: setContextUser } = useContext<AuthContextValue>(AuthContext);
  const { clearApplicationContext } = useContext(SofApplicationContext);

  const checkCurrentUser = async (setCurrentUser: (user: any) => void, onCheckFinish?: () => void) => {
    try {
      const user: any = await Auth.currentAuthenticatedUser();
      setCurrentUser(user);
      Cache.setItem('token', (user as any).signInUserSession?.idToken?.jwtToken);
    } catch (error) {
      console.error(error);
    }
    if (onCheckFinish) {
      onCheckFinish();
    }
  };

  const signIn = async (username: string, password: string, onError?: (e: any) => void) => {
    try {
      const user: CognitoUser = await Auth.signIn(username, password);
      setContextUser(user);
      Cache.setItem('token', (user as any).signInUserSession?.idToken?.jwtToken);
      console.log('login successful');
      return user;
    } catch (e) {
      if (onError) {
        onError(e);
      }
      return null;
    }
  };

  const signOut = async () => {
    try {
      await Auth.signOut();
      setContextUser(null);
      clearApplicationContext();
      Cache.clear();
    } catch (error) {
      console.log('error signing out: ', error);
    }
  };

  const respondToNewPasswordChallenge = async (
    username: string,
    newPassword: string,
    tempPassword: string,
    onError?: (error: any) => void,
  ) => {
    try {
      const user: CognitoUser = await Auth.signIn(username, tempPassword);
      if (!user) {
        throw new Error('Invalid temp password');
      }
      const attributes = {
        given_name: user?.username,
        family_name: 'placeholder',
      };
      const result = await Auth.completeNewPassword(user, newPassword, attributes);
      if (result?.user) {
        await Auth.signOut();
      }
      return result;
    } catch (error) {
      console.log(error);
      if (onError) {
        onError(error);
      }
      return null;
    }
  };

  // Reset a password - Send confirmation code to user's email
  const resetPassword = async (email: string, onError?: (error: any) => void): Promise<boolean> => {
    try {
      const result = await Auth.forgotPassword(email);
      return !!result;
    } catch (error) {
      if (onError) {
        onError(error);
      }
      return false;
    }
  };

  // Collect confirmation code and new password
  const changePasswordWithCode = async (
    email: string,
    code: string,
    password: string,
    onError?: (error: any) => void,
  ): Promise<boolean> => {
    try {
      const result = await Auth.forgotPasswordSubmit(email, code, password);
      return result === 'SUCCESS';
    } catch (e) {
      console.log(e);
      if (onError) {
        onError(e);
      }
      return false;
    }
  };

  // Change a password while users signed into their account
  const changePassword = async (
    user: CognitoUser | null,
    newPassword: string,
    oldPassword: string,
    attributes: any,
    onError?: (error: any) => void,
  ) => {
    try {
      const result = await Auth.changePassword(user, oldPassword, newPassword, attributes);
      return result === 'SUCCESS';
    } catch (error) {
      console.log(error);
      if (onError) {
        onError(error);
      }
      return false;
    }
  };

  // Update user attributes while users signed into their account
  const updateUserAttributes = async (user: any, attributes: any, onError?: (error: any) => void): Promise<boolean> => {
    try {
      const result = await Auth.updateUserAttributes(user, attributes);
      return result === 'SUCCESS';
    } catch (e) {
      if (onError) {
        onError(e);
      }
      return false;
    }
  };

  // Return current jwtToken
  const getAccessToken = async () => {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    return (cognitoUser as any)?.signInUserSession.idToken.jwtToken;
  };

  return {
    signIn,
    signOut,
    respondToNewPasswordChallenge,
    changePassword,
    changePasswordWithCode,
    checkCurrentUser,
    resetPassword,
    updateUserAttributes,
    getAccessToken,
  };
};
